<template>
  <div>
    <Header></Header>
    <div class="main_container contact">
      <div class="main_banner contact_banner"></div>
      <div class="container_content">
        <!--      <div class="contact_content_title">联系我们</div>-->
        <!--      <div class="contact_message">-->
        <!--        <div class="contact_area compnay">-->
        <!--          <baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler">-->
        <!--            <bm-marker :position="markerPoint" :rotation="markerRotation"-->
        <!--              :icon="{ url: require('@/assets/img/maker.png'), size: { width: 150, height: 80 } }" />-->
        <!--            <bm-info-window :position="{ lng: 113.032265, lat: 28.071722 }" title="公司坐标" :show="infoWindow.show"-->
        <!--              @close="handleInfoWindowClose" @open="handleInfoWindowOpen">-->
        <!--            </bm-info-window>-->
        <!--            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"-->
        <!--              :autoLocation="true"></bm-geolocation>-->
        <!--            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>-->
        <!--          </baidu-map>-->
        <!--        </div>-->
        <!--        <div class="contact_info">-->
        <!--          <div>联系地址：XX省XX市XX区XX号</div>-->
        <!--          <div>联系电话：020-000-0000</div>-->
        <!--          <div>联系邮箱：xxx@.co.m</div>-->
        <!--        </div>-->
        <!--      </div>-->
        <div class="outbox">
          <div class="item-top">
            <div class="top_inner">
              <div class="top_inner_item top_inner_left">
                <h1>客户服务</h1>
                <div class="content">
                  <span>联系电话：400-022-9558</span>
                  <span>官方邮箱：dwychina@163.com</span>
                  <span>地址信息：长沙市雨花区金海路158号</span>
                </div>
                <div class="liji_contact" @click="contactUs">立即联系</div>
              </div>
            </div>
            <div class="top_inner" @mouseenter="isHover=true" @mouseleave="isHover=false">
              <div class="top_inner_item top_inner_middle">
                <h1>免费获取解决方案</h1>
                <div class="content">
                  <span>预约销售顾问，与您面对面沟通</span>
                  <img src="http://www.tryine.com/app/static/computer/images/xg_01.png"/>
                </div>
                <div class="online_contact" :class="isHover?'active_online_contact':''" @click="contactUs">在线联系
                </div>
              </div>
            </div>
            <div class="top_inner">
              <div class="top_inner_item top_inner_right">
                <h1>业务合作</h1>
                <img src="../assets/img/contact/erweima.png"/>
              </div>
            </div>
          </div>
          <div class="item-bottom">
            <div class="bottom_inner">
              <div class="bottom_inner_left">
                <div class="item_title">有需求？请咨询</div>
                <div class="item_describe">
                  <span>市场总监</span>
                  <span style="font-size: 16px;">（24小时服务热线）</span>
                </div>
                <div class="phone">400 022 9558</div>
              </div>
              <div class="bottom_inner_right">
                <img src="../assets/img/contact/erweima.png">
                <span>扫描二维码，加我微信</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <createContact ref="contactForm"></createContact>
    <Footer></Footer>
  </div>
</template>

<script>
import createContact from "@/components/createContact.vue";
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "contactUs",
  components: {Footer, Header, createContact},
  data() {
    return {
      center: {lng: 0, lat: 0},
      zoom: 3,
      map: null,
      BMap: null,
      markerPoint: {lng: 113.032265, lat: 28.071722},
      markerRotation: 0,
      infoWindow: {
        show: true,
        position: {},
        title: '',
      },
      isHover: false,
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 在线联系
    contactUs() {
      this.$refs.contactForm.form = {
        companyName: '',
        name: '',
        phone: '',
        phoneCode: '',
        remark: '',
      }
      this.$refs.contactForm.dialogFormVisible = true;
    },

    handler({BMap, map}) {
      this.map = map
      this.BMap = BMap
      this.center.lng = 113.032265
      this.center.lat = 28.071722
      this.zoom = 15
    },
    clickHandler(e) {
      console.log(e)
    },
    handleInfoWindowOpen() {
      this.infoWindow.show = true
    },
    handleInfoWindowClose() {
      this.infoWindow.show = false
    },
    handleInfoWindowClickClose() {
      this.infoWindow.show = false
      this.infoWindow.position = {}
      this.$refs.diseaseWindow.redraw()
    },
  }
}
</script>

<style scoped lang="scss">
.contact {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 0;

  .outbox {
    width: 60%;
    height: auto;
    margin: 0 auto;
    //background: #262525;
    display: flex;
    flex-direction: column;

    .item-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top_inner {
        background-color: #fff;
        width: 31%;
        height: 340px;
        box-sizing: border-box;

        .top_inner_item {
          width: 80%;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
          align-items: center;

          h1 {
            margin-top: 50px;
            text-align: center;
            font-size: 25px;
          }
        }

        .top_inner_left {
          .content {
            display: flex;
            height: 140px;
            flex-direction: column;
            justify-content: space-around;
            font-size: 14px;
          }

          .liji_contact {
            cursor: pointer;
            width: 120px;
            height: 40px;
            background: #EA5404;
            margin-top: 30px;
            color: #fff;
            font-size: 16px;
            text-align: center;
            line-height: 40px;
          }
        }

        .top_inner_middle {
          .content {
            display: flex;
            height: 140px;
            flex-direction: column;
            align-items: center;
            font-size: 14px;
            justify-content: space-around;

            img {
              width: 64px;
              height: 70px;
            }
          }

          .online_contact {
            width: 120px;
            height: 40px;
            background-color: #2E2E2F;
            color: #fff;
            font-size: 16px;
            margin-top: 30px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
          }

          .active_online_contact {
            background: #EA5404 !important;
          }
        }

        .top_inner_right {
          img {
            width: 190px;
            height: 190px;
            transition: all 0.4s;
          }
        }

        .top_inner_right img:hover {
          transform: scale(1.1);
        }
      }
    }

    .item-bottom {
      display: flex;
      width: 100%;
      height: 315px;
      background-color: #fff;
      margin-top: 4%;
      //background: url('http://www.tryine.com/app/static/computer/images/xg_03.jpg') no-repeat;
      background-size: 100% 100%;
      background-position: center;

      .bottom_inner {
        display: flex;
        justify-content: space-between;
        width: 72%;
        height: 100%;
        padding: 3% 0 0 0;

        .bottom_inner_left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 47%;
          margin-left: 23%;
          margin-top: 5%;
          color: #323232;

          .item_title {
            font-size: 34px;
            font-weight: 600;
          }

          .item_describe {
            span:nth-child(1) {
              font-size: 24px;
              display: block;
              font-weight: 600;
            }
          }

          .phone {
            font-size: 26px;
            font-weight: 600;
          }
        }

        .bottom_inner_right {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 200px;
            height: 200px;
          }

          span {
            font-size: 12px;
            color: #A4A4A4;
          }
        }
      }
    }
  }

  .container_content {
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
    background: #eaeaea;

    .contact_content_title {
      text-align: center;
      font-size: 36px;
      color: #373737;
    }

    .contact_message {
      display: flex;
      margin-top: 60px;
      position: relative;

      .contact_area {
        width: 800px;
        height: 300px;
        border: thin solid gray;

        .bm-view {
          width: 100%;
          height: 100%; //BaiduMap 组件容器本身是一个空的块级元素，如果容器不定义高度，百度地图将渲染在一个高度为 0 不可见的容器内。
        }
      }

      .company {
        width: 800px;
        height: 300px;
        position: absolute;
        top: 0;
        left: 0;
      }

      .contact_info {
        margin-left: 60px;
        color: #373737;
        line-height: 30px;
      }
    }
  }
}

.contact_banner {
  background: url(~@/assets/img/contact/contact_bg.png) center center;
}
</style>
